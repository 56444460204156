// ** React Imports
import { ReactNode } from 'react';

// ** Layout Import
import BlankLayout from 'apps/webapp/src/@core/layouts/BlankLayout';
import ErrorPage from './error';
import { ErrorTypes } from '../types/error';

const Error404 = () => {
  return <ErrorPage type={ErrorTypes.fourOFour} />;
};

Error404.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

export default Error404;
