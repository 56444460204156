import { Box, Button, Typography, useTheme } from '@mui/material';
import CustomIcon from '../@core/components/custom-icons';
import IconNames from '../constants/IconNames';
import Link from 'next/link';
import { ErrorTypes } from '../types/error';

interface ErrorPage {
  type: ErrorTypes;
}
interface Details {
  iconName: string;
  heading: string;
  subHeading?: string;
  steps?: string[];
}

const getIconNames = (type: ErrorTypes): Details => {
  switch (type) {
    case ErrorTypes.maintenance:
      return {
        iconName: IconNames.maintainenceError,
        heading: 'Maintenance going on!',
        subHeading: 'Oops! Seems like some work is going on behind this page',
      };
    case ErrorTypes.connectionLost:
      return {
        iconName: IconNames.connectionLostError,
        heading: 'Connection Lost!',
        steps: [
          'Check your connection Settings',
          'Reboot your device',
          'If all else fails,ponder the mysteries of the offline world',
        ],
      };
    case ErrorTypes.fourOFour:
      return {
        iconName: IconNames.fourOFourError,
        heading: 'Oh No!',
        subHeading: 'Oops! That page seems to have taken a detour.',
      };
    default:
      return {
        iconName: IconNames.fourOFourError,
        heading: 'Oh No!',
        subHeading: 'Oops! That page seems to have taken a detour.',
      };
  }
};

const ErrorPage = ({ type = ErrorTypes.fourOFour }: ErrorPage) => {
  const theme = useTheme();
  const details: Details = getIconNames(type);
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: '10000',
        width: '100vw',
        height: '100vh',
        right: '0',
        left: '0',
        top: '0',
        bottom: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: theme.palette.common.white,
      }}
    >
      <CustomIcon name={details.iconName} />
      <Typography sx={{ fontSize: '50px', fontWeight: '700', py: '26px' }}>{details.heading}</Typography>
      {!!details?.subHeading && (
        <Typography sx={{ fontSize: '24px', color: theme.palette.grey[500] }}>{details.subHeading}</Typography>
      )}
      {!!details?.steps && (
        <Box>
          {details?.steps?.map((step: string, index: number) => {
            return (
              <Typography key={index} sx={{ fontSize: '24px', color: theme.palette.grey[500] }}>
                {index + 1}. {step}
              </Typography>
            );
          })}
        </Box>
      )}
      {type !== ErrorTypes.connectionLost && (
        <Button
          href='/'
          component={Link}
          variant='contained'
          sx={{ mt: '37px', fontSize: '12px', fontWeight: '700', padding: '11px 21px', borderRadius: '39px' }}
        >
          Back to HomePage
        </Button>
      )}
    </Box>
  );
};

export default ErrorPage;
